import { PortableText } from '@portabletext/react'
import { graphql, PageProps } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Helmet } from 'react-helmet'
import Seo from '../components/Seo'
import MainLayout from '../layouts/MainLayout'

const HomePage: React.FC<PageProps<any>> = ({ data }) => {
  return (
    <MainLayout>
      <Seo url="/" />
      <Helmet>
        <meta name="description" content="" />
      </Helmet>
      <div className="relative grid w-full min-h-[calc(100vh-11rem)] grid-cols-1 md:grid-cols-10">
        <StaticImage
          src="../assets/bg.jpg"
          alt="A busy office"
          placeholder="tracedSVG"
          layout="constrained"
          width={960}
          objectFit="cover"
          objectPosition="right"
          className="col-span-6"
        />
        <div className="relative z-10 col-span-4 p-4 bg-white md:container md:flex md:flex-col md:justify-center md:p-8">
          <div className="mb-2 text-xl leading-8 text-black md:mb-4 md:text-xl xl:text-2xl">
            {data?.sanitySiteSettings?.frontPageTitle}
          </div>
          <div className="mb-2 text-4xl font-black text-black md:mb-4 md:text-4xl xl:text-6xl">
            {data?.sanitySiteSettings?.frontPageHeading}
          </div>
          <div className="leading-7 text-black md:text-xl 2xl:text-2xl xl:leading-8">
            <div className="inline-block prose prose-xl">
              <PortableText
                value={data.sanitySiteSettings?._rawFrontPageContent}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="max-w-screen-sm p-4 m-auto mt-16 text-white">
        <div className="mb-4 text-4xl font-black md:mb-8 md:text-4xl xl:text-5xl">
          Latest Posts
        </div>
        {data?.allWpPost?.nodes?.map((post: any) => (
          <div key={post.id} className="w-full mt-16">
            <Link
              to={post.uri}
              className="block mb-4 text-2xl transition-colors md:mb-8 md:text-2xl xl:text-3xl hover:text-pink-500"
            >
              {post.title}
            </Link>
            <div className="block mb-4 leading-7 md:mb-8 md:text-lg 2xl:text-xl 2xl:leading-8 text-cText">
              <div dangerouslySetInnerHTML={{ __html: post.excerpt }} />
            </div>
            <Link
              to={post.uri}
              className="block mb-4 text-xl text-right transition-colors md:mb-8 xl:text-2xl hover:text-pink-500"
            >
              Read More »
            </Link>
          </div>
        ))}
      </div> */}
    </MainLayout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    sanitySiteSettings {
      frontPageHeading
      frontPageTitle
      _rawFrontPageContent
    }
  }
`

export default HomePage
